<template>
  <!-- 
    画面: プラン料金／掲載サイト一覧
    用途: プラン料金／掲載サイトを参照する
   -->
  <div>

    <!-- #region List -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>

          <CCardHeader>
            <CRow>
              <CCol md="3">
                <strong class="card-title mr-3">{{ $t("PlanPriceListPage.pageTitle") }}</strong>
              </CCol>
              <CCol>
                <span class="float-right">
                  <CAlert
                    color="info"
                    class="float-left py-0  my-0 mx-1"
                  >
                    <strong>{{ $t("PlanPriceListPage.headerPlanName") }} : </strong>
                    {{ header.planName}}
                  </CAlert>
                  <CAlert
                    color="info"
                    class="float-left py-0  my-0 mx-1"
                  >
                    <strong>{{ $t("PlanPriceListPage.headerRoomTypeName") }} : </strong>
                    {{ header.roomTypeName}}
                  </CAlert>
                </span>
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody>

            <CForm class="submit_form">

              <CRow>
                <CCol class="label"><strong>{{ $t("PlanPriceListPage.priceCalendar") }}</strong></CCol>
                <CCol md="2">
                  <CSelect
                    class="mx-3 slt_bg"
                    :value.sync="priceCalendar"
                    :options="priceCalendarList"
                    @update:change="onpriceCalendarSelected()"
                  />
                </CCol>
              </CRow>

              <!-- #region Table -->
              <CDataTable
                border
                hover
                size="sm"
                :fields="tableFields"
                :items="tableItems"
              >
                <td
                  slot="name"
                  slot-scope="{ item }"
                >
                  <span class="ml-2 text-left">{{ item.name }}</span>
                </td>
                <td
                  slot="status"
                  slot-scope="{ item }"
                  class="text-center p-0"
                >
                  <CSwitch
                    class="mt-2 swc sw-2"
                    color="info"
                    variant="opposite"
                    :labelOn='$t("common.active")'
                    :labelOff='$t("common.inActive")'
                    :checked="item.status | masterStatus"
                    @update:checked="onStatusChanged(item)"
                  />
                </td>
                <template #action="{ item }">
                  <td class="p-0 text-center">
                    <CButton
                      color="info"
                      square
                      size="sm"
                      class="px-3 mt-2"
                      @click="onEditClicked(item.id)"
                    ><i class="icon cil-pencil mr-1"></i> {{ $t("common.edit") }}</CButton>
                  </td>
                </template>
              </CDataTable>
              <!-- #endregion Table -->

            </CForm>

          </CCardBody>
          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion List -->

  </div>
</template>

<script>
// import SiteListTable from "./components/ListTable";
import axios from "axios";
import STATUS from "@/mixins/property";

// const STATUS = {
//   VALID: 100,
//   INVALID: 200,
// };

export default {
  name: "PlanPriceList",

  mixins: [STATUS],

  computed: {
    uid() {
      return this.$store.state.uniqueId;
    },

    planId() {
      return this.$route.params.planId;
    },

    roomTypeId() {
      return this.$route.params.roomTypeId;
    },
  },

  data() {
    return {
      //#region Card header
      header: {
        planName: "plan a",
        roomTypeName: "roomtype a",
      },
      //#endregion Card header

      //#region Table

      /** 一覧列名 */
      tableFields: [
        {
          key: "name",
          label: this.$t("common.name"),
          _classes: "text-center bg-light",
        },
        {
          key: "status",
          label: this.$t("common.switchActive"),
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
        {
          key: "action",
          label: this.$t("common.action"),
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
      ],

      // tableItems: [],
      tableItems: [
        {
          name: "オフィシャルサイト",
          status: STATUS.VALID,
          url: "https://",
        },
        {
          name: "宿さがし.COM",
          status: STATUS.VALID,
          url: "https://",
        },
      ],

      priceCalendar: 2,

      priceCalendarList: [
        { value: 1, label: "Aパターン" },
        { value: 2, label: "Bパターン" },
      ],

      //#endregion Table

      //#region Response data

      response: {},

      //#endregion Response data
    };
  },

  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged(item) {
      // 有効無効ステータス更新リクエスト
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    /** 編集ボタン押下 */
    onEditClicked(siteId) {
      this.$router.push({
        // path: `/admin/plan/${this.planId}/roomType/${this.roomTypeId}/site/${siteId}/edit`, // TODO: 開発中
        path: `/operator/plan/${this.planId}/roomType/${this.roomTypeId}/site/${siteId}/edit`,
      });
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    /** 料金カレンダー選択 */
    onpriceCalendarSelected() { },

    /** 料金カレンダー選択 */
    onSalesTypeSelected() { },

    //#endregion Event

    //#region Request

    /** 一覧取得 */
    reqGetAll() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}/room/${this.roomTypeId}/site`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          // console.log(resp.data);

          this.response = resp.data;
          this.pourTable(this.response.sites);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /** 有効無効ステータス更新 */
    reqPutStatus(item) {
      const siteId = item.id;
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}/roomType/${this.roomTypeId}/site/${siteId}/status`;
      const body = { status: item.status };

      console.log(url);
      console.log(body);

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.successModal = true;
          this.reqGetAll();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //#endregion Request

    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          shortName: data.shortName,
          name: data.name,
          code: data.code,
          url: data.url,
          status: data.status, // 100: valid, 200: invalid, 201: new
        });
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    //
    toggleStatus(status) {
      return status == this.STATUS.VALID ? this.STATUS.INVALID : this.STATUS.VALID;
    },

    createPutBody(item) {
      return {
        status: item.status, // 100: valid, 200: invalid, 201: new
      };
    },

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method
  },

  beforeMount() {
    this.reqGetAll();
  },
};
</script>
